var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w560 themev2"},[(_vm.error)?_c('div',{staticClass:"w300"},[(_vm.error)?_c('div',{staticClass:"formerror"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('br')]):_vm._e(),(_vm.loading)?_c('span',{staticClass:"spinner profilespinner"}):_vm._e(),_vm._m(0),(_vm.$store.state.auth.status != 'register_success')?_c('ValidationObserver',{ref:"veeform",staticClass:"w300",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"form",class:{disabled: _vm.$store.getters.loading || _vm.loading},attrs:{"method":"POST","spellcheck":"false"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('label',{attrs:{"for":"organization"}},[_vm._v("Organization")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.organization),expression:"form.organization"}],staticStyle:{"color":"#80878B"},attrs:{"type":"text","novalidate":"","autocomplete":"off","disabled":""},domProps:{"value":(_vm.form.organization)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "organization", $event.target.value)}}}),_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],attrs:{"disabled":"","name":"email","type":"email","novalidate":""},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('ValidationProvider',{staticClass:"inputrow",attrs:{"name":"firstName","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"firstName"}},[_vm._v("First name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"}],class:classes,attrs:{"name":"firstName","type":"text","novalidate":"","autocomplete":"off","disabled":_vm.$store.getters.loading || _vm.loading},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstName", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"inputrow",attrs:{"name":"lastName","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"lastName"}},[_vm._v("Last name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"}],class:classes,attrs:{"name":"lastName","type":"text","novalidate":"","autocomplete":"off","disabled":_vm.$store.getters.loading || _vm.loading},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastName", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"inputrow",attrs:{"name":"phone","rules":"required|phone","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"phone"}},[_vm._v("Phone number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone),expression:"form.phone"}],class:classes,attrs:{"name":"phone","type":"tel","novalidate":"","autocomplete":"off","disabled":_vm.$store.getters.loading || _vm.loading},domProps:{"value":(_vm.form.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "phone", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{ref:"timeZoneValidator",staticClass:"inputrow",attrs:{"name":"timezone","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"timezone"}},[_vm._v("Time zone")]),_c('vSelect',{class:classes,staticStyle:{"width":"100%","clear":"both"},attrs:{"disabled":_vm.$store.getters.loading || _vm.loading,"placeholder":"Select preferred time zone","name":"timezone","options":_vm.timeZones,"clearable":false,"reduce":function (tz) { return tz.id; },"label":"id"},on:{"close":_vm.validateSelectedTimeZone},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false))]}},{key:"option",fn:function(tz){return [_vm._v(" "+_vm._s(tz.id)+" (GMT"+_vm._s(tz.offset >= 0 ? '+':'')+_vm._s(tz.offset)+") ")]}},{key:"selected-option",fn:function(tz){return [_vm._v(" "+_vm._s(tz.id)+" (GMT"+_vm._s(tz.offset >= 0 ? '+':'')+_vm._s(tz.offset)+") ")]}}],null,true),model:{value:(_vm.form.timeZone),callback:function ($$v) {_vm.$set(_vm.form, "timeZone", $$v)},expression:"form.timeZone"}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('h3',[_vm._v("Change password")]),_c('ValidationProvider',{ref:"newPassword",staticClass:"inputrow",attrs:{"name":"newPassword","mode":"aggressive","rules":"password","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"newPassword"}},[_vm._v("New Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newPassword),expression:"form.newPassword"}],class:classes,attrs:{"name":"newPassword","type":"password","novalidate":"","placeholder":"","disabled":_vm.$store.getters.loading || _vm.loading},domProps:{"value":(_vm.form.newPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "newPassword", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{staticClass:"inputrow",attrs:{"name":"newPassword2","rules":"confirmed:newPassword|required_if:newPassword","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"newPassword2"}},[_vm._v("Repeat new password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newPassword2),expression:"form.newPassword2"}],class:classes,attrs:{"name":"newPassword2","type":"password","novalidate":"","placeholder":"","disabled":_vm.$store.getters.loading || _vm.loading},domProps:{"value":(_vm.form.newPassword2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "newPassword2", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('br'),_c('ValidationProvider',{ref:"oldPassword",staticClass:"inputrow",attrs:{"name":"oldPassword","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"oldPassword"}},[_vm._v("Current Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.oldPassword),expression:"form.oldPassword"}],class:classes,attrs:{"name":"oldPassword","type":"password","novalidate":"","placeholder":"Password required to update profile info","disabled":_vm.$store.getters.loading  || _vm.loading},domProps:{"value":(_vm.form.oldPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "oldPassword", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('button',{staticClass:"full",class:{disabled: invalid},attrs:{"type":"submit","disabled":invalid}},[_vm._v("Save")]),(_vm.$store.getters.loading)?_c('span',{staticClass:"spinner"}):_vm._e(),(_vm.$store.state.auth.error)?_c('div',{staticClass:"formerror"},[_vm._v(_vm._s(_vm.$store.state.auth.error))]):_vm._e(),(_vm.$store.state.auth.status == 'profile_update_success')?_c('div',{staticClass:"formsuccess"},[_c('span',{staticClass:"checkmark"}),_vm._v(" Profile updated successfully ")]):_vm._e()],1)]}}],null,false,4223488244)}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w300"},[_c('h1',[_vm._v("Profile details")])])}]

export { render, staticRenderFns }